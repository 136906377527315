import styled, { keyframes } from "styled-components";

const animation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerIcon = styled.div`
  border: 3px solid var(--gray-500);
  border-left-color: var(--white);
  border-radius: 9999px;
  padding: 0.4rem;
  animation: 0.7s ${animation} linear infinite;
`;
