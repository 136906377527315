import { Container } from "./styles";
import type { ButtonHTMLAttributes } from "react";
import { SpinnerIcon } from "../SpinnerIcon";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  icon?: JSX.Element;
  size?: "sm" | "md" | "lg" | "xl" | "2xl";
  styleType?: "default" | "outline" | "ghost";
  warning?: boolean;
  success?: boolean;
  info?: boolean;
  danger?: boolean;
}

export function Button({
  icon,
  children,
  isLoading,
  disabled,
  size = "md",
  styleType = "default",
  warning,
  success,
  info,
  danger,
  ...rest
}: ButtonProps) {
  return (
    <Container
      {...rest}
      size={size}
      isInfo={!!info}
      isSuccess={!!success}
      isWarning={!!warning}
      isDanger={!!danger}
      disabled={isLoading || disabled}
      className={styleType}
    >
      <>
        {icon && !isLoading && { icon }}
        {isLoading && <SpinnerIcon />} {children}
      </>
    </Container>
  );
}
