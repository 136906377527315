import styled, { css } from "styled-components";

type ContainerProps = {
  size: "sm" | "md" | "lg" | "xl" | "2xl";
  isWarning: boolean;
  isInfo: boolean;
  isDanger: boolean;
  isSuccess: boolean;
};

function defineSizeButton(size: string) {
  switch (size) {
    case "sm":
      return "0.5rem";
    case "md":
      return "0.8rem";
    case "lg":
      return "1rem";
    case "xl":
      return "1.5rem";
    case "2xl":
      return "2rem";
  }
}

function defineBackgroundHoverGhost(props: ContainerProps) {
  if (props.isDanger) return "var(--danger-100)";
  if (props.isWarning) return "var(--warning-100)";
  if (props.isInfo) return "var(--info-100)";
  if (props.isSuccess) return "var(--success-100)";
  return "var(--gray-200)";
}

export const Container = styled.button<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  padding: ${({ size }) => defineSizeButton(size)};
  flex: 1;

  border: none;
  border-radius: 6px;
  transition: background-color 0.2s;

  font-size: 1rem;
  font-weight: 600;

  &.default {
    color: var(--white);
    background-color: var(--color-3);

    &:not(:disabled):hover {
      background-color: var(--color-2);
    }

    &:disabled {
      background-color: var(--color-2);
    }

    ${({ isDanger }) =>
      isDanger &&
      css`
        background-color: var(--danger-300);
        &:disabled {
          background-color: var(--danger-500);
        }
        &:not(:disabled):hover {
          background-color: var(--danger-500);
        }
      `}
  }

  &.outline {
    border: 1px solid var(--gray-300);
    background-color: inherit;

    &:not(:disabled):hover {
      background-color: var(--gray-100);
    }

    &:disabled {
      background-color: var(--gray-100);
    }
  }

  &.ghost {
    background-color: inherit;

    &:not(:disabled):hover {
      background-color: ${(props) => defineBackgroundHoverGhost(props)};
    }

    &:disabled {
      background-color: ${(props) => defineBackgroundHoverGhost(props)};
    }
  }
`;
